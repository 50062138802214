
@import "variables";
@import "mixins";

/*--------------------------------------------------------------
# Normalize
--------------------------------------------------------------*/
@import '../bower_components/normalize-css/normalize';

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
@import "typography";

/*--------------------------------------------------------------
# Partials
--------------------------------------------------------------*/
@import "main";

/*--------------------------------------------------------------
# Font Awesome
--------------------------------------------------------------*/
@import "../bower_components/font-awesome/scss/variables";
$fa-font-path:"fonts";
@import "../bower_components/font-awesome/scss/mixins";
@import "../bower_components/font-awesome/scss/path";
@import "../bower_components/font-awesome/scss/core";
@import "../bower_components/font-awesome/scss/larger";
@import "../bower_components/font-awesome/scss/fixed-width";
@import "../bower_components/font-awesome/scss/list";
@import "../bower_components/font-awesome/scss/bordered-pulled";
@import "../bower_components/font-awesome/scss/animated";
@import "../bower_components/font-awesome/scss/rotated-flipped";
@import "../bower_components/font-awesome/scss/stacked";
@import "font-awesome/icons";
@import "../bower_components/font-awesome/scss/screen-reader";

/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/
@import "responsive";
