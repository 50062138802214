
body{background: url(img/bgTex.png) #fff;}

#wrapper{
	width: 100%;
	height: 100%;
	position: absolute;
	
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjZmZmZmZmIiBzdG9wLW9wYWNpdHk9IjAiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjExIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
	background: -moz-linear-gradient(-45deg,  rgba(255,255,255,0) 0%, rgba(0,0,0,0.11) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,rgba(255,255,255,0)), color-stop(100%,rgba(0,0,0,0.11))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(-45deg,  rgba(255,255,255,0) 0%,rgba(0,0,0,0.11) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(-45deg,  rgba(255,255,255,0) 0%,rgba(0,0,0,0.11) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(-45deg,  rgba(255,255,255,0) 0%,rgba(0,0,0,0.11) 100%); /* IE10+ */
	background: linear-gradient(135deg,  rgba(255,255,255,0) 0%,rgba(0,0,0,0.11) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#1c000000',GradientType=1 ); /* IE6-8 fallback on horizontal gradient */
	
	
}
header{
	margin-top: 10%;
	//width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;

}
footer{
	display: flex;
	flex-direction: column;
	justify-content: center;
}
#boxLogo{
	width: 5em;
	margin: 4em auto 0.4em;

	img{
		max-width: 100%;
	}

}
#boxName{
	text-align: center;
	line-height: 1em;
	color: #666;
}
#boxEmail{
	text-align: center;
	margin-top: 15%;
	letter-spacing: 0.1em;
}
#boxLogo div{	
	background: url(img/bgTex.png) #e9e9e9;
	box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.4);
	margin-left: 0.3em;
	margin-right: 0.3em;
	float: left;
	width: 0.9em;
}
.logoLines{
	height: 4em;	
}
.logoDots{
	height: 0.9em;
	border-radius: 50%;
	margin-top: 1.6em;
}

.emailImg{
	width: 12.5em;
}